import React from 'react'
import './App.css';

var path = process.env.PUBLIC_URL;
var cookie = "cookie.png";

function App() {
  return (
    <div className="App">
      <body className="App-header">
        <img src={path + cookie} className="App-logo" alt="logo" />
        <p>
            Welcome to the WildCookie's home!
        </p>
        <p>
            Feel free to scroll this shit out
        </p>
        <p>
            What the heck is WildCookie?
        </p>
      </body>
    </div>
  );
}

export default App;
